.clear-both { clear: both; }

.hide-thank-you, .hide-loading, .hide-button, .hide-form, .hide-artist, .hide-artist-list {
  display: none;
}

.show-form, .show-thank-you, .show-artist, .show-artist-list {
  display: block;
}

.button-100 {
  width: 100%;
}
