*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

label {
  margin-bottom: 0.375rem;
}

hr.row {
  margin: 2rem 0;
}

.mt-1 {
  margin-top: 0.75rem;
}

.hide-me, .winner-false {
  display: none !important;
}
.show-me {

}

.clear-both {
  clear:both;
}

.container {
  padding-top:20px;

}

.editor-wrapper {
  border: 1px solid #eee;

}
.editor-editor {
  padding: 20px;
}

.container-margin {
  max-width: 1000px;
  margin: 0 auto;
}

.switch-wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.switch-wrapper > div {
  position: absolute;
  width: 100vw;
  height: 100vh;
}

.title-div {
  padding-bottom: 20px;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.header-logo {

}

.App-intro {
  font-size: large;
}

.campaign-image {
  max-width: 100%;
  margin: 20px auto;
}
.product-thumbnail {
  max-width: 90px;
}

.pagination li {
  text-align: center;
  display: inline;
  margin-left: 10px;
}

.pagination li:first-child {
  margin-left: 0px;
}

.pagination {
  list-style: none;
}

.element-appear {
  opacity: 0.01;
}

.element-appear.element-appear-active {
  opacity: 1;
  transition: opacity 1.7s ease-in;
}

.show-loading {
  display: block;
}

.hide-loading, .hide-no-data {
  display: none;
}

.no-data {
  display: block;
}

.side-bar {
  padding: 20px 0 0 0px;
  box-sizing: border-box;
  text-align: left;
  background: #eaeaea;
}

.side-bar ul {
  list-style: none;
}

.side-bar ul li a {
  color: #333;
  font-size: 12px;
  text-transform: uppercase;
}

.content-container {
  text-align: left;
  box-sizing: border-box;
  padding: 20px 20px 20px 220px;

}

.content-wrapper-sidebar {

}

.side-bar {
  position: absolute;
  height: 100%;
  width: 200px;
}

.json-input-box {
  height: 200px;
}

.not-allowed {
  cursor: not-allowed;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.section {
  margin-top: 3em;
  margin-bottom: 3em;
}

.Checkbox-Grid {
  list-style: none;
  margin: 0;
}

.Checkbox-Grid li {
  margin-bottom: 1em;
}

.SettingsWeight__Label {
  line-height: 2.8rem;
}

.SettingsWeight__Result.tag {
  float: right;
  margin-right: 0;
}

.SubmissionTable .tag {
  margin-right: 0;
  line-height: 1.2;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  width: 100%;
  text-align: center;
}

.SubmissionTable-status {
  display: flex;
  align-items: center;
  justify-content: center;
}

.SubmissionTable-confirmed {
  margin-top: 0.5em;
}

.SubmissionTable .tag .fa {
  margin-right: 0.5em;
  width: 1em;
  height: 1em;
  display: inline-block;
  color: inherit;
}

.CampaignSubmission__ActionButtons .button,
.CampaignSubmission__ActionButtons button {
  margin-bottom: 1em;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.fadeIn {
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 2s; /* Firefox < 16 */
        -ms-animation: fadein 2s; /* Internet Explorer */
         -o-animation: fadein 2s; /* Opera < 12.1 */
            animation: fadein 2s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
