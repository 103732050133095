.TagBox {
  min-height: 3.4rem;
  position: relative;
  padding: 0.5rem 1rem 0;
  background-color: #fff;
  border: 0.1rem solid #c4cdd5;
  border-radius: 0.3rem;
  color: #31373d;
  display: block;
  width: 100%;
  min-width: 7.5rem;
  max-width: 100%;
  box-shadow: 0 0 0 1px transparent, 0 1px 0 0 rgba(22, 29, 37, 0.05);
  transition: box-shadow 0.2s cubic-bezier(0.64, 0, 0.35, 1);
  cursor: text;
  list-style: none;
  margin: 0;
}

.TagBox--focus {
  border-color: #5c6ac4;
  box-shadow: inset 0 0 0 0 transparent, 0 0 0 0.1rem #5c6ac4;
}

.TagBox li {
  margin: 0 0 0.5rem 0;
  float: left;
}

input.TagBox__Input {
  max-width: 10rem;
  border: 0;
  padding: 0;
  margin-right: 0.5rem;
  min-height: auto;
}

input.TagBox__Input:focus {
  box-shadow: none;
}

.TagBox__Error {
  color: #ed6347;
  padding: 0.5rem;
}
