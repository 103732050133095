.RichTextEditor .rdw-editor-toolbar {
  border-bottom: 0.1rem solid #dfe4e8;
}

.RichTextEditor .rdw-image-modal-btn {
  color: #212b36;
}

.RichTextEditor .rdw-image-modal-btn:hover,
.RichTextEditor .rdw-image-modal-btn:focus {
  color: #fff;
}

.RichTextEditor .editor-wrapper {
  background-color: #fff;
  border: 0.1rem solid #c4cdd5;
  border-radius: 0.3rem;
  color: #31373d;
  box-shadow: 0 0 0 1px transparent,
              0 1px 0 0 rgba(22, 29, 37, 0.05);
  transition: box-shadow 0.2s cubic-bezier(0.64, 0, 0.35, 1),
              -webkit-box-shadow 0.2s cubic-bezier(0.64, 0, 0.35, 1);
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.RichTextEditor .editor-wrapper--focus {
  border-color: #5c6ac4;
  box-shadow: inset 0 0 0 0 transparent, 0 0 0 0.1rem #5c6ac4;
}

.RichTextEditor .editor-editor {
  padding: 0.5rem 1rem;
  line-height: 1.6;
  height: 200px;
  cursor: text;
  z-index: 3;
}

.RichTextEditor .public-DraftStyleDefault-block {
  margin: 0.5em 0;
}

.RichTextEditor .DraftEditor-root {
  height: auto;
}
